import { Menu, notification, Switch } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router";
import dLogo from "../Assets/Images/dashbrd-logo.svg";
import { notifyInAppError, notifyInAppSuccess } from "../components/UtilitiesTS";
import { utility } from "../components/Utility";
import { DeveloperRedux } from "../redux/actions/Developer/DevEmail";
import { profileSetting } from "../redux/actions/ProfileSetting/ProfileSetting";
import { useAppDispatchJS, useAppSelectorJS } from "../redux/hooks";
import { useAppDispatchTS } from "../redux/hooksTS";
import { reduxSetUserDarkMode } from "../redux/slicesTS/UsersSlice";

const Header = ({ togglebtn, headerRef }) => {
  const dispatch = useAppDispatchJS();
  const dispatchTS = useAppDispatchTS();
  const navigate = useNavigate();
  // var userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [showPauseNotific, setPauseNotificShow] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [snoozeTime, setSnoozeTime] = useState();
  const imageUpload = useAppSelectorJS((state) => state.ImageUpload);
  const profileInfo = useAppSelectorJS((state) => state.ProfileInfo);


  useEffect(()=>{
    if(Object.keys(imageUpload.data).length > 0)
    {
      userDetails.profile_image = imageUpload.data?.profile_image;
      localStorage.setItem("userDetails", JSON.stringify(userDetails));
      setUserDetails(JSON.parse(localStorage.getItem("userDetails")));
    }
  },[imageUpload , profileInfo]);

  useEffect(()=>{
    if(Object.keys(profileInfo.data).length > 0)
    {
      userDetails.profile_image = profileInfo.data?.profile_image;
      userDetails.timezone = JSON.parse(localStorage.getItem("userDetails"))?.timezone;
      userDetails.timezone_id = JSON.parse(localStorage.getItem("userDetails"))?.timezone_id;
      localStorage.setItem("userDetails", JSON.stringify(userDetails));
      setUserDetails(JSON.parse(localStorage.getItem("userDetails")));
    }
  },[ profileInfo])

  const Notify = (type, mesage, icons) => {
    notification[type]({
      description: mesage,
      icon: icons,
      top: 65,
    });
  };
  const handlePauseNotificClose = () => {
    setPauseNotificShow(false);
  };
  const handlePauseNotificShow = () => setPauseNotificShow(true);

  function logout() {
    localStorage.setItem("user", false);
    localStorage.removeItem("token");
    localStorage.removeItem("shareFileUser");
    localStorage.removeItem("financialUrl");
    navigate("/");
  }

  window.addEventListener("click", (event) => {
    if(!event.target.className.includes("close-drop") && !event.target.className.includes("icon-chevron-down-outline") ){
      setShowDropDown(false);
    }else if(event.target.className.includes("icon-chevron-down-outline header-icon")){
      setShowDropDown(true);
    }
  });

  function handleAddNewMenuClick(e) {
    if (e.key === "logout") {
      logout();
    }
  }


  function snoozeNotification(time) {
    let param = {
      snooze_minutes: parseInt(time),
    };
    dispatch(profileSetting.snoozeNotification(param)).then((response) => {
      // setLoader(false);
      if (response.type === "NOTIFICATION_SNOOZE_SUCCESS") {
        Notify(
          "success",
          time
            ? `Notifications paused until ` +
                `${moment().add(time, "minutes").format("LT")}`
            : "Resume Notifications",
          <em className="icon-check-outline"></em>
        );
        setShowDropDown(false);
        getSnoozeNotification();
      }
    });
  }

  function getSnoozeNotification() {
    dispatch(profileSetting.getSnoozeNotification()).then((response) => {
      if (response.type === "GET_NOTIFICATION_SNOOZE_SUCCESS") {
        setSnoozeTime(response?.data?.snoozed_time);
      }
    });
  }
  const AddNewmenu = (
    <Menu onClick={handleAddNewMenuClick}>
      <Menu.Item key="logout">Logout</Menu.Item>
    </Menu>
  );

  // ### DARK MODE, ONLY FOR DEVELOPERS ###

  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    if (userDetails.email === "tfry@monetagroup.com" || userDetails.email === "tannerf1101@yahoo.com"
      || userDetails.email === "brett.m.woodruff@gmail.com" || userDetails.email === "bwoodruff@monetagroup.com"
    ) {
      setIsDarkMode(false);
      dispatchTS(reduxSetUserDarkMode(false));
    }    
  }, []);

  const toggleLightMode = (checked) => {
    if (userDetails.email === "tfry@monetagroup.com" || userDetails.email === "tannerf1101@yahoo.com"
      || userDetails.email === "brett.m.woodruff@gmail.com" || userDetails.email === "bwoodruff@monetagroup.com"
    ) {
      setIsDarkMode(checked);
      dispatchTS(reduxSetUserDarkMode(checked));
    }
  }

  // ### DARK MODE, ONLY FOR DEVELOPERS ###

  return (
    <>
      <header className="d-flex align-items-center justify-content-between" ref={headerRef}>
        <div className="menuicon d-lg-none">
          <button
            className="menu-toggle"
            type="button"
            onClick={() => togglebtn()}
          >
            <em className="icon-menu-outline"></em>
          </button>
        </div>
        <a className="cursor-pointer">
          <img src={dLogo} alt="" className="header-image"/>
        </a>
        <div className="d-flex align-items-center justify-content-end header-right">
          <div className="user-drop d-flex align-items-center">
            {(userDetails?.email === "tfry@monetagroup.com" || userDetails?.email === "tannerf1101@yahoo.com" 
            || userDetails.email === "brett.m.woodruff@gmail.com" || userDetails.email === "bwoodruff@monetagroup.com") ? (
              <>
                <div className="light-dark-mode-switch me-16 header-text-color">
                  <span className="me-16">Dark Mode</span>
                  <Switch checked={isDarkMode} onChange={toggleLightMode} />
                </div>
                {(userDetails?.email === "tfry@monetagroup.com" || userDetails?.email === "tannerf1101@yahoo.com") && (
                  <div className="test-email-send-button">
                    <button
                      className="btn btn-success"
                      style={{ padding: "5px 10px", margin: "0 10px" }}
                      onClick={() => {
                        dispatchTS(DeveloperRedux.GetSendTestEmail()).then((response) => {
                          if (response.type === "GET_SEND_TEST_EMAIL_SUCCESS") {
                            if (response.data?.error === "Error sending test email.") {
                              notifyInAppError("Failed to send test email. Environment must have it disabled.");
                            } else {
                              notifyInAppSuccess("Test email sent successfully");
                            }
                          } else {
                            notifyInAppError("Failed to send test email: ", + response.error.error);
                          }
                        });
                      }}
                    >
                      Send Test Email
                    </button>
                  </div>
                )}
              </>
            ) : null}
            <div className="d-none d-lg-flex align-items-center h-user-text text-end">
              {/* <Dropdown
                className="d-none d-md-flex align-items-center"
                overlay={AddNewmenu}
              > */}
              <div className="">
                <div className="flex-fill">
                  <span className="d-block user-name header-text-color">
                    Hi, {userDetails?.first_name + " " + userDetails?.last_name}
                  </span>
                  <span className="d-block user-email header-text-color">
                    {userDetails?.email}
                  </span>
                </div>
              </div>
              {/* </Dropdown> */}
            </div>
            {/* user-dropdown-open */}
            <div className="user-clickarea position-relative">
              <div className="d-flex align-items-center">
                <span
                  className="dropbtn-arrow icon-24 me-16 d-none d-md-flex container-dropdown header-text-color"
                  onClick={(e) => {
                    setShowDropDown(!showDropDown);
                    getSnoozeNotification();
                  }}
                >
                  <em className="icon-chevron-down-outline header-icon"></em>
                </span>
                <div
                  className="user-photo"
                  onClick={(e) => {
                    navigate("/dashboard/profile");
                  }}
                >
                  {utility.getProfileImage(
                    userDetails?.profile_image,
                    userDetails?.first_name?.charAt(0),
                    userDetails?.last_name?.charAt(0)
                  )}
                </div>
              </div>
              <div
                className={
                  showDropDown
                    ? "userdropdown-menu d-block  dropdown-menu"
                    : "userdropdown-menu  dropdown-menu"
                }
              >
                <ul className="pb-8 mb-8 border-bottom">
                  <li>
                    <a
                      href="#"
                      className="dropdown-item d-flex align-items-center justify-content-between"
                    >
                      <span
                        className="d-flex align-items-center close-drop"
                        onClick={(e) => {
                          navigate("/dashboard/profile");
                          setShowDropDown(false);

                        }}
                      >
                        <span className="icon-20 me-8">
                          <em className="icon icon-user-filled text-muted"></em>
                        </span>
                        Manage Account
                      </span>
                    </a>
                  </li>
                  <li className="d-none d-md-block">
                    <a
                      href="#"
                      className="dropdown-item d-flex align-items-center justify-content-between"
                    >
                      <span className="d-flex align-items-center close-drop">
                        <span className="icon-20 me-8">
                          <em className="icon icon-pause-filled text-muted"></em>
                        </span>
                        Pause Notifications
                      </span>
                      <span className="icon-20 cursor-pointer">
                        <em className="icon icon-chevron-right-outline"></em>
                      </span>
                    </a>
                    <div className="dropdown-menu">
                      {snoozeTime && (
                        <ul className="p-0 pb-0 border-bottom">
                          <li>
                            <a href="#" className="dropdown-item fw-500">
                              Notifications paused until{" "}
                              {  moment(snoozeTime).format('MM-DD-YYYY') +" "+moment(snoozeTime).local().format("LT")}
                            </a>
                          </li>
                        </ul>
                      )}
                      {snoozeTime && (
                        <ul
                          className="p-0 border-bottom"
                          onClick={(e) => {
                            snoozeNotification(0);
                          }}
                        >
                          <li>
                            <a href="#" className="dropdown-item text-danger">
                              Resume notifications
                            </a>
                          </li>
                        </ul>
                      )}
                      <ul className="p-0">
                        <li
                          onClick={(e) => {
                            snoozeNotification(30);
                          }}
                        >
                          <a href="#" className="dropdown-item">
                            For 30 minutes
                          </a>
                        </li>
                        <li
                          onClick={(e) => {
                            snoozeNotification(60);
                          }}
                        >
                          <a href="#" className="dropdown-item">
                            For 1 hour
                          </a>
                        </li>
                        <li
                          onClick={(e) => {
                            snoozeNotification(120);
                          }}
                        >
                          <a href="#" className="dropdown-item">
                            For 2 hours
                          </a>
                        </li>
                        <li
                          onClick={(e) => {
                            let today = new Date();
                            var now = new Date();
                            now.setDate(now.getDate() + 1);
                            now.setHours(8);
                            now.setMinutes(0);
                            now.setMilliseconds(0);
                            var diffMs = now.getTime() - today.getTime();
                            var diffMins = Math.round(diffMs / 60000); // minutes
                            snoozeNotification(diffMins);
                          }}
                        >
                          <a href="#" className="dropdown-item">
                            Until 8:00 AM tomorrow
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="d-md-none">
                    <a
                      href="#"
                      className="dropdown-item d-flex align-items-center justify-content-between"
                      onClick={(e) => {
                        e.preventDefault();
                        handlePauseNotificShow();
                      }}
                    >
                      <span className="d-flex align-items-center close-drop">
                        <span className="icon-20 me-8">
                          <em className="icon icon-pause-filled text-muted"></em>
                        </span>
                        Pause Notifications
                      </span>
                      <span className="icon-20 cursor-pointer">
                        <em className="icon icon-chevron-right-outline"></em>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://monetagroup.com/disclosures" target="_blank"
                      rel="noopener noreferrer"
                      className="dropdown-item d-flex align-items-center justify-content-between"
                    >
                      <span className="d-flex align-items-center close-drop">
                        <span className="icon-20 me-8">
                          <em className="icon icon-document-duplicate-filled text-muted"></em>
                        </span>
                        Privacy Policy
                      </span>
                    </a>
                  </li>
                </ul>
                <ul>
                  <li onClick={(e) => logout()}>
                    <a
                      href="#"
                      className="dropdown-item text-danger d-flex align-items-center"
                    >
                      <span className="icon-24 me-8">
                        <em className="icon icon-logout-filled"></em>
                      </span>
                      Log out
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* Pause Notifications Model */}
      <Modal size="sm" show={showPauseNotific} onHide={handlePauseNotificClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="d-flex align-items-center">
              <span className="icon-20 me-16">
                <em className="icon icon-pause-filled text-muted"></em>
              </span>{" "}
              Pause Notifications
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <ul className="list-unstyled bulk-list proflist m-0">
            <li className="fw-500 border-bottom">
              Notifications paused until 05:11 PM
            </li>
            <li className="text-danger border-bottom">For 30 minutes</li>
            <li>For 30 minutes</li>
            <li>For 1 hour</li>
            <li>For 2 hours</li>
            <li>Until 8:00 AM tomorrow</li>
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Header;
