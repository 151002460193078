/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import outlook from "../../Assets/Images/Outlook.svg";
import CalToday from "../../Assets/Images/icon-today.svg";
import microsoftTeams from "../../Assets/Images/microsoft-teams.svg";
import Loading from "../../components/Loader";
import { calendar } from "../../redux/actions/Calendar/Calendar";
import moment from "moment";
import { notification } from "antd";
import { utility } from "../../components/Utility";
import videoCamera from "../../Assets/Images/video-camera.svg";
import CalendarCheckIcon from "../../Assets/Images/calendar-check-green.svg";
import MeetingDetailsTemplate from "./MeetingDetailsTemplate";
import CopyToClipboard from 'react-copy-to-clipboard';
import { useAppDispatchJS } from "../../redux/hooks";


const MeetingDetails = (props) => {
  const [mettingDetails, setMettingDetails] = useState(false);
  const handleMettingDetailsClose = () => setMettingDetails(false);
  const handleMettingDetailsShow = () => setMettingDetails(true);
  const [timezone, settimezone] = useState("");
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [currentUser, setCurrentUser] = useState();
  const [loader, setLoader] = useState(false);
  const [event, setEvent] = useState();

  const dispatch = useAppDispatchJS();

  useEffect(() => {
    var zone_name = moment.tz.guess();
    settimezone(moment.tz(zone_name).zoneAbbr());
    console.log(props);
  }, [props.visible]);

  useEffect(() => {
    console.log(props.event);
    eventDetails();
  }, [props.event]);

  function sortOrganizer(data) {
    var index = data?.participants?.findIndex(function (item, i) {
      return item.uuid === data?.organizer?.uuid;
    });
    var currentUserStatus = data?.participants?.findIndex(function (item, i) {
      return item.uuid === userDetails?.uuid;
    });
    setCurrentUser(data?.participants[currentUserStatus]);
    data?.participants.unshift(data?.participants?.splice(index, 1)[0]);
    setEvent(data);
  }

  const Notify = (type, mesage, icons) => {
    notification[type]({
      description: mesage,
      icon: icons,
      top: 65,
    });
  };

  function eventDetails() {
    setLoader(true);
    dispatch(calendar.eventDetails(props?.event?.uuid)).then((response) => {
      if (response.type === "GET_EVENT_SUCCESS") {
        sortOrganizer(response.data);
        setLoader(false);
        const { visible, closeModal } = props;
        setMettingDetails(visible);
      } else {
        setLoader(false);
      }
    });
  }

  function acceptDeclineMeeting(status) {
    let param = {
      status: status === "accept" ? "accept" : "reject",
      comment: status === "accept" ? "Accepted" : "Rejected",
    };
    setLoader(true);
    dispatch(calendar.accpetOrDeclineMeeting(param, props?.event?.uuid)).then(
      (response) => {
        setLoader(false);
        if (response.type === "ACCEPT_DECLINE_SUCCESS") {
          if (status === "accept") {
            const { closeModal } = props;
            closeModal();
            Notify(
              "success",
              `${event?.title.includes("_")  ? event?.title.substring(0, event?.title.indexOf("_") ): event?.title} was scheduled on ${moment(props?.event?.start_time).tz(userDetails?.timezone?.live_meeting).format("MMM DD, LT")}`,
              <img src={CalendarCheckIcon} alt="" />
            );
          }
        } else {
          setLoader(false);
        }
      }
    );
  }



  function notifyOnNoLocation() {
    Notify(
      "error",
      `A meeting room must be selected prior to accepting
      the meeting request.`,
      <em className="icon-exclamation-circle-filled"></em>
    );
  }

  function inPersonMetting() {
    return <> <span className="me-4">In-Person Meeting</span>
      {userDetails?.user_type !== "clients" ? <span className="text-danger"> Missing In-Person Meeting Location </span> : null}</>
  }

  return (
    <div>
      {loader && <Loading noTimeOut={loader} />}
      <Modal
        className="modal-full meeting-detail-modal modal-lmd"
        show={mettingDetails}
        onHide={() => {
          handleMettingDetailsClose;
          const { closeModal } = props;
          closeModal();
        }}
      >
        <Modal.Header closeButton className="border-bottom pb-24">
          <Modal.Title>Meeting Details</Modal.Title>
          {event?.metadata?.is_made_from_nylas && (
            <span
              className={
                event?.organizer?.uuid === userDetails?.uuid ||
                userDetails?.user_type !== "clients"
                  ? "cursor-pointer icon-24 meeting-edit "
                  : " icon-24 meeting-edit text-muted"
              }
              onClick={(e) => {
                if (
                  event?.organizer?.uuid === userDetails?.uuid ||
                  userDetails?.user_type !== "clients"
                ) {
                  setMettingDetails(false);
                  const { editRequestMeeting } = props;
                  editRequestMeeting();
                } else {
                }

                //setEditMettingDetails(true);
              }}
            >
              <em className="icon-pencil-filled"></em>
            </span>
          )}
        </Modal.Header>

        <Modal.Body>
          <div className="mb-16">
            <h5 className="fs-18 m-0">{event?.title.includes("_")  ? event?.title.substring(0, event?.title.indexOf("_") ): event?.title}</h5>
            {!event?.metadata?.is_made_from_nylas && (
              <div className="text-muted d-flex align-items-center">
                Meeting created in Outlook
                <img src={outlook} alt="" className="ms-4" />
              </div>
            )}
          </div>
          {event?.metadata?.is_made_from_nylas && (
            <div className="d-flex align-items-center mb-20">
              <span className="icon-24">
                <em
                  className={
                    event?.status === "pending"
                      ? "icon-clock-filled fs-18"
                      : event?.status === "declined"
                      ? "icon-x-circle-outline text-danger fs-18"
                      : "icon-check-circle-filled text-success fs-18"
                  }
                ></em>
              </span>
              <div className="ms-8">
                {event?.status.charAt(0).toUpperCase() + event?.status.slice(1)}
              </div>
            </div>
          )}{" "}
          <div className="d-flex align-items-center mb-20">
            {" "}
            <span className="icon-24">
              <em className="icon-calendar-filled fs-18"></em>
            </span>
            <div className="ms-8">
              {/* {moment(event?.start_time)
                ?.tz(event?.timezone?.live_meeting)
                ?.format("ddd, MMM DD , LT ") +
                " - " +
                moment(event?.end_time)
                  ?.tz(event?.timezone?.live_meeting)
                  ?.format("LT") +
                " " +
                event?.timezone?.abbreviation} */}
              {moment(props?.event?.start_time)
                                  .tz(userDetails?.timezone?.live_meeting).format("ddd, MMM DD,LT") +
                " - " +
                moment(props?.event?.end_time).tz(userDetails?.timezone?.live_meeting).format("LT")}
              {" " + moment.tz(props?.event?.start_time,userDetails?.timezone?.live_meeting).zoneAbbr()}
            </div>
          </div>
          {(event?.event_type === "Virtual Meeting" ||
            event?.event_type === "virtual_meeting") && (
            <div className="d-flex  mb-20">
              <span className="icon-24">
                <img className="w-22 " src={event?.virtual_meeting_link?.includes("teams") ? microsoftTeams : videoCamera } alt="" />
              </span>
              <div className="ms-8">
                <h6 className="m-0">Virtual Meeting</h6>
                <a
                  href={event?.virtual_meeting_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-success text-break"
                >
                  {event?.virtual_meeting_link}
                </a>
              </div>
            </div>
          )}
          {(event?.event_type !== "Virtual Meeting" &&
            event?.event_type !== "virtual_meeting") && (
              <div className="d-flex align-items-center mb-20">
                {" "}
                <span className="icon-24">
                  <em className="icon-office-filled fs-18"></em>
                </span>
                <div className="ms-8">
                  <h6 className="mb-0">
                    {!event?.location_address
                      ? event?.location === null
                        ? inPersonMetting()
                        : <u><a>
                        <CopyToClipboard
                          text={event?.location}
                          format="html"
                          onCopy={(text, result) => {
                            if (result) { 
                              Notify(
                                  "success",
                                  "Address copied"
                                ) 
                            }
                          }}
                        >
                          <span>{event?.location}</span>
                        </CopyToClipboard>
                      </a></u>
                      : event?.location}
                  </h6>
                  <u><a>
                    <CopyToClipboard
                      text={event?.location_address}
                      format="html"
                      onCopy={(text, result) => {
                        if (result) { 
                          Notify(
                              "success",
                              "Address copied"
                            ) 
                        }
                      }}
                    >
                      <span>{event?.location_address}</span>
                    </CopyToClipboard>
                  </a></u>
                </div>
              </div>
            )}
          <div className="d-flex  mb-20">
            <span className="icon-24">
              <em className="icon-users-filled fs-18"></em>
            </span>
            <div className="ms-8">
            {event?.ext_organizer_email &&
                <div
                  className="meet-user-block d-flex align-items-center"
                >
                  <div className="meet-photo-outer me-8">
                    <span className="meet-photo">
                      {utility.getProfileImage(
                        null,
                        event?.ext_organizer_email?.charAt(0),
                        ''
                      )}
                    </span>
                  </div>
                  <div className="lh-20">
                    <span className="d-block">
                      {event?.ext_organizer_email}
                      {/* {client?.uuid === userDetails?.uuid
                        ? " (You)"
                        : " (" + client?.user_type + ")"} */}
                    </span>
                    <span className="d-block fw-500">Organizer</span>
                  </div>
                </div>
              }
              {event?.participants?.filter((v, i, a) => a?.findIndex(t => (t?.uuid === v?.uuid)) === i)?.map((client, index) => (
                <div
                  className="meet-user-block d-flex align-items-center"
                  key={index}
                >
                  <div className="meet-photo-outer me-8">
                    <span className="meet-photo">
                      {utility.getProfileImage(
                        client?.profile_image,
                        client?.first_name?.charAt(0),
                        client?.last_name?.charAt(0)
                      )}
                    </span>
                    {client?.status === "accept" && (
                      <span className="checked-meet">
                        <em className="icon-check-outline"></em>
                      </span>
                    )}
                    {client?.status === "reject" && (
                      <span className="checked-meet">
                        <em className="icon-x-circle-outline text-danger"></em>
                      </span>
                    )}
                  </div>
                  <div className="lh-20">
                    <span className="d-block">
                      {client?.first_name + " " + client?.last_name}
                      {/* {client?.uuid === userDetails?.uuid
                        ? " (You)"
                        : " (" + client?.user_type + ")"} */}
                    </span>
                    {client?.uuid === event?.organizer?.uuid && (
                      <span className="d-block fw-500">Organizer</span>
                    )}
                  </div>
                </div>
              ))}
              {event?.external_participants?.attendees?.map((client, index) => (
                <div
                  className="meet-user-block d-flex align-items-center"
                  key={index}
                >
                  <div className="meet-photo-outer me-8">
                    <span className="meet-photo">
                      {utility.getProfileImage(
                        null,
                        client?.email?.charAt(0),
                        ''
                      )}
                    </span>
                  </div>
                  <div className="lh-20">
                    <span className="d-block">
                      {client?.email}
                      {/* {client?.uuid === userDetails?.uuid
                        ? " (You)"
                        : " (" + client?.user_type + ")"} */}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="d-flex">
            <span className="icon-24">
              <em className="icon-clipboard-filled fs-18"></em>
            </span>
            
            {event?.description?.includes("<html")?
            <p className="ms-8 text-break"
              dangerouslySetInnerHTML={{
                __html: 
                  event?.description.replaceAll(
                    /<img/g,
                    "<img style=\"width:50px; height:50px\""
                  )
              }}
            ></p>:
            <div className="ms-8 text-break">
              {event?.description.toLowerCase().includes("join on your computer")?
              <MeetingDetailsTemplate
                description={event?.description.toLowerCase()}
              />:
              event?.description
              }
            </div>
            }
            {/* <div className="ms-8 text-break"> */}
              {/* {event?.description?.length > 300
                ? event?.description?.slice(0, 300) + "..."
                : event?.description} */}
                {/* {event?.description?.includes("<html>") ? utility.getDescription( event?.description) : event?.description}
            </div> */}
          </div>
        </Modal.Body>
        {userDetails?.user_type === "clients" &&
          event?.metadata?.is_made_from_nylas && (
            <Modal.Footer className="border-top py-16">
              <button
                className="btn border-0"
                disabled={
                  event?.organizer?.uuid === userDetails?.uuid &&
                  event?.metadata?.is_made_from_nylas
                    ? false
                    : true
                }
                onClick={() => {
                  console.log("Hello World");
                  const { editModal } = props;
                  editModal(event);
                }}
              >
                Reschedule
              </button>
              <button
                className="btn text-danger border-0"
                variant="secondary"
                disabled={
                  event?.organizer?.uuid === userDetails?.uuid &&
                  event?.metadata?.is_made_from_nylas
                    ? false
                    : true
                }
                onClick={(e) => {
                  const { CancelRequestModal } = props;
                  CancelRequestModal();
                }}
              >
                Cancel Request
              </button>

              {/* <button
              className="btn text-danger"
              variant="secondary"
              disabled={
                event?.organizer?.uuid === userDetails?.uuid &&
                event?.metadata?.is_made_from_nylas
                  ? false
                  : true
              }
              onClick={(e) => {
                const { deleteModal } = props;
                setMettingDetails(false);
                deleteModal();
              }}
            >
              Delete
            </button> */}
            </Modal.Footer>
          )}
        {(userDetails?.user_type !== "clients" &&
          event?.metadata?.is_made_from_nylas && event?.host?.uuid !== userDetails?.uuid) &&(
            <Modal.Footer className="border-top py-16">
              {/* {event?.location !== "in_person_meeting" && */}
              <button
                className="btn text-success border-0"
                //disabled = {}
                disabled={currentUser?.status === "accept" ? true : false}
                onClick={() => {
                  if (
                    event?.event_type === "in_person_meeting" &&
                    event?.location === null &&
                    event?.room_resource_id === null
                  ) {
                    notifyOnNoLocation();
                  } else {
                    acceptDeclineMeeting("accept");
                  }
                }}
              >
                Accept
              </button>
              {/* } */}
              <button
                className="btn text-danger border-0"
                variant="secondary"
                //disabled={event?.metadata?.is_made_from_nylas ? false : true}
                disabled={currentUser?.status === "reject" ? true : false}
                onClick={(e) => {
                  //acceptDeclineMeeting("decline");
                  const { declineRequestMeeting } = props;
                  declineRequestMeeting();
                }}
              >
                Decline
              </button>
            </Modal.Footer>
          )}
      </Modal>
    </div>
  );
};

export default MeetingDetails;
